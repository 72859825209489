import styled, { css } from 'styled-components'
import { FormControlLabel } from '@mui/material'

export const CheckboxStyled = styled(FormControlLabel)`
  ${({ theme }) => css`
    column-gap: ${theme.spacing(1)};

    input[type='checkbox'] + svg {
      color: ${theme.colors.brown};
    }

    .MuiCheckbox-root:hover {
      background: transparent;
    }

    .checkbox-icon {
      ${theme.mixins.flexCenter}
      ${theme.mixins.square(theme.spacing(3))}

      color: ${theme.colors.white};
      border: 1px solid ${theme.colors.brown};
      background: ${theme.colors.white};
      border-radius: ${theme.spacing(0.5)};
      transition: all 0.2s;

      &.checked {
        background: ${theme.colors.brown};
      }
    }

    .Mui-disabled .checkbox-icon {
      background-color: ${theme.mixins.opacity(theme.colors.white, 0.4)};
      border-color: ${theme.mixins.opacity(theme.colors.brown, 0.4)};

      &.checked {
        background-color: ${theme.mixins.opacity(theme.colors.brown, 0.4)};
      }
    }
  `}
`
